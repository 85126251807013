import * as PropTypes from "prop-types"
import { graphql } from "gatsby"
import React from "react"

import { get } from "../helpers/utils"
import Header from "../components/Header"
import SEO from "../components/shared/Seo"
import WorkDetail from "../components/WorkDetail"

const WorkTemplate = props => {
    const title = get(
        props,
        "pageResources.json.data.worksJson.name",
        "Архитектурный проект"
    )

    return (
        <>
            <SEO title={`Проект - ${title}`} />
            <Header siteAuthor={props.data.site.siteMetadata.author} />
            <main>
                <WorkDetail work={props.data.worksJson} />
            </main>
        </>
    )
}

WorkTemplate.propTypes = {
    data: PropTypes.shape({
        worksJson: PropTypes.object.isRequired,
    }),
}

export default WorkTemplate

// The work template's GraphQL query. Notice the “id”
// variable which is passed in. We set this on the page
// context in gatsby-node.js.
//
// All GraphQL queries in Gatsby are run at build-time and
// loaded as plain JSON files so have minimal client cost.
export const pageQuery = graphql`
    query($id: String!) {
        # Select the work which equals this id.
        worksJson(id: { eq: $id }) {
            ...WorkDetail_details
            name
            address
            category
        }
        site {
            siteMetadata {
                author
            }
        }
    }
`
