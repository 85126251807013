import Img from "gatsby-image"
import { graphql } from "gatsby"
import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"
import Fade from "react-reveal/Fade"
import * as PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import ScrollMenu from "react-horizontal-scrolling-menu"
import Carousel, { Modal, ModalGateway } from "react-images"
import { useLockBodyScroll, useLocation, useMedia } from "react-use"

import Text from "../shared/Text"
import Link from "../shared/Link"
import { colors, CATEGORIES } from "../../helpers/variables"
import { Section, ImgWrapper, Header, Desktop, Mobile } from "./styled"
import { get, getCategory, getRouteByCategory } from "../../helpers/utils"

const WorkDetail = props => {
    const isWide = useMedia("(min-width: 992px)")

    const location = useLocation()
    const { pathname } = location

    const [currentIdx, setCurrentIdx] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const toggleModal = idx => {
        !isModalOpen && setCurrentIdx(idx)
        setIsModalOpen(!isModalOpen)
    }

    const [locked, setLocked] = useState(!isWide ? false : true)
    const [currentPathname] = useState(() => pathname)

    useLockBodyScroll(locked)

    useEffect(() => {
        new Glide(".glide").mount()
    })

    useEffect(() => {
        if (!isWide) return

        // Удаляю scroll lock когда ухожу со страницы проекта
        if (currentPathname !== pathname) setLocked(false)
    }, [pathname, currentPathname, isWide])

    const { work } = props
    const { name, category, address } = work
    const images = get(work, "images", [])

    const carouselImages = images.map(item => {
        return {
            src: get(item, "childImageSharp.fluid.src", ""),
        }
    })

    const menu = images.map((img, idx) => {
        const id = get(img, "id", idx)
        const fluid = get(img, "childImageSharp.fluid", {})

        return (
            <ImgWrapper key={id} onClick={() => toggleModal(idx)}>
                <Img
                    alt={name}
                    fluid={fluid}
                    style={{ height: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                />
            </ImgWrapper>
        )
    })

    const CATEGORY = getCategory(category)

    return (
        <>
            <ModalGateway>
                {isModalOpen ? (
                    <Modal onClose={toggleModal}>
                        <Carousel
                            views={carouselImages}
                            currentIndex={currentIdx}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>

            <Section>
                <div className="container">
                    <Fade>
                        <Header>
                            <Link
                                color={colors.green}
                                to={getRouteByCategory(CATEGORY)}
                            >
                                <>
                                    {CATEGORY}{" "}
                                    {CATEGORY !== CATEGORIES.INTER && "проекты"}
                                </>
                            </Link>
                            <Text variant="h2" align="center">
                                {name}
                            </Text>
                            <Text variant="h5" align="center">
                                {address}
                            </Text>
                        </Header>
                    </Fade>
                </div>

                <Desktop>
                    <ScrollMenu
                        data={menu}
                        hideArrows
                        scrollBy={1}
                        wheel={isWide}
                        dragging={!isWide}
                        inertiaScrolling={!isWide}
                    />
                </Desktop>

                <Mobile>
                    <div className="glide">
                        <div data-glide-el="track" className="glide__track">
                            <ul className="glide__slides">
                                {menu.map((item, idx) => (
                                    <li key={idx} className="glide__slide">
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Mobile>
            </Section>
        </>
    )
}

WorkDetail.propTypes = {
    work: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        category: PropTypes.string,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                childImageSharp: PropTypes.shape({
                    fluid: PropTypes.object,
                }),
            })
        ),
    }),
}

export default WorkDetail

export const workDetailFragment = graphql`
    fragment WorkDetail_details on WorksJson {
        id
        images {
            childImageSharp {
                fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
