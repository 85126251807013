import styled from "styled-components"

import { PureButton } from "../shared/styled"
import { media } from "../../helpers/variables"

export const Section = styled.section`
    padding-top: 120px;

    .menu-wrapper--inner {
        height: auto;

        ${media.lg} {
            height: 450px;
        }
    }

    .gatsby-image-wrapper,
    .menu-item-wrapper {
        height: 100%;
    }
`

export const ImgWrapper = styled(PureButton)`
    width: 100%;
    height: 100%;
    max-height: auto;
    cursor: zoom-in !important;

    ${media.lg} {
        width: 670px;
        margin: 0 20px;
        max-height: 450px;
    }
`

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    width: 100%;

    h2 {
        padding: 15px 0 20px 0;
    }
`

export const Desktop = styled.div`
    display: none;
    margin-top: 50px;

    ${media.lg} {
        display: block;
    }
`

export const Mobile = styled.div`
    display: block;
    margin-top: 50px;

    ${media.lg} {
        display: none;
    }
`
